<template>
  <div>
    <div class="th-mb-36 d-flex align-items-center">
      <div class="flex-grow-1">
        <h5 v-html="$translation.t('Cookie Consent Banner')"></h5>
        <p
          class="body2"
          v-html="$translation.t('cookie.consent.index.description')"
        ></p>
      </div>
      <div>
        <v-btn
          color="primary"
          :to="{ name: 'new_cookie_consent', params: { ...$route.params } }"
        >
          <v-icon class="th-mr-8">mdi-plus</v-icon>
          {{ $translation.t("Add new cookie consent") }}
        </v-btn>
      </div>
    </div>
    <page-loader v-if="loading" />
    <template v-else>
      <th-card
        class="th-px-24 th-mb-12 project-document"
        body-classes="th-p-12"
        v-for="(ccb, ccKey) in cookieConsents || []"
        :key="ccKey"
      >
        <div
          class="project-document__item th-py-16 d-flex align-items-center justify-content-between"
        >
          <div
            class="d-flex flex-column flex-md-row align-items-center flex-grow-1"
          >
            <div class="th-mr-12">
              <v-img
                :src="require('@/assets/images/cookie_consent.svg')"
                :alt="ccb.name"
                height="32"
                width="32"
              />
            </div>
            <h6
              class="subtitle2 th-m-0"
              v-html="
                $translation.t('Cookie Consent Banner for {website}', {
                  website: `<strong>${ccb.name}</strong>`,
                })
              "
            ></h6>
          </div>
          <v-btn color="primary" outlined :to="manageCcb(ccb.uuid)">
            {{ $translation.t("Manage") }}
          </v-btn>
        </div>
      </th-card>
    </template>
  </div>
</template>

<script>
import ThCard from "../../../components/GeneralComponents/ThCard";
import PageLoader from "../../../components/PageLoader";
export default {
  name: "CookieConsentBannerIndex",
  components: { PageLoader, ThCard },
  data() {
    return {
      cookieConsents: [],
      loading: true,
    };
  },
  async beforeCreate() {
    this.cookieConsents = await this.$axios.get(
      `/api/v1/business/cookie-consent`
    );
    this.loading = false;
  },
  methods: {
    manageCcb(uuid) {
      return {
        name: "cookie_consent",
        params: {
          ...this.$route.params,
          uuid,
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped></style>
